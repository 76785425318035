import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Header from "../components/header"
import BackgroundWrapper from "../components/wrapper-background"
import ContentWrapper from "../components/wrapper-content"
import theme from "../theme"

const Content = styled(ContentWrapper)`
  color: ${theme.color.white}; 
`

const Contact = () => (
  <Layout>
    <SEO 
      title="We have cancelled your order"
      description="" />

    <Header />

    <BackgroundWrapper className="backgroundBlue">

      <Content>
 
        <h1>We have cancelled your order</h1>
        <p>If you have any questions please get in touch with our customer service team at <a href="mailto:hola@chivacoffee.com">hola@chivacoffee.com</a></p> 
     
      </Content>
      
    </BackgroundWrapper>

  </Layout>
)

export default Contact
